/* src/components/CepLookup/CepLookup.less */
.cep-lookup {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  .btn-consultar {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }

  input {
    padding: 10px;
    font-size: 16px;
    margin-right: 10px;
  }

  .result{

    ul{
      list-style: none;
    }
  
  }
  .loader {
    margin-top: 20px;
  }
  
}