@color_1: inherit;
@background_color_1: #f0f0f0;
@background_color_2: #e0e0e0;


.navigation-link {
	text-decoration: none;
	color: @color_1;
}
.navigation-box {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 200px;
	height: 100px;
	margin: 20px;
	background-color: @background_color_1;
	border: 1px solid #ccc;
	border-radius: 10px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	transition: background-color 0.3s, transform 0.3s;
	&:hover {
		background-color: @background_color_2;
		transform: translateY(-2px);
	}
}
