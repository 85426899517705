@color_1: white;
@background_color_1: #282c34;


.App {
	text-align: center;
}
.App-header {
	background-color: @background_color_1;
	min-height: 50px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: @color_1;
  height: 50px;

  .header-content{
    display:flex;
    align-items: center;
    height: 50px;
    .menu-icon{
      width:50px;
    }
    .logo{
      display:flex;
      height:40px;
      align-items: center;
    }
  }

  .drawer {
    position: fixed;
    left: 0;
    top: 0;
    width: 240px;
    height: 100%;
    background-color: #333;
    color:#fff;
    overflow: auto;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    z-index: 1000;
    cursor:pointer;

    ul{
      padding:10px;
      &:hover{
        background:#000;
      }
      li{

        a{
          color:#fff;
          text-decoration: none;
          font-size:1rem;
        }
      }
    }
  }
  
  .drawer.open {
    transform: translateX(0);
  }
  

}
nav {

	ul {
		list-style: none;
		padding: 0;
		li {
			display: inline;
			list-style: none;
			margin: 0 10px;
		}
	}
}
main {
	padding: 20px;
}

